import React, { Component } from 'react';

class Skills extends Component {
    render() {
        return (
            <section className="mh-skills" id="mh-skills">
                    <div className="home-v-img">
                        <div className="container">
                            <div className="row section-separator">
                                <div className="section-title text-center col-sm-12">

                                </div>
                                <div className="col-sm-12 col-md-6">
                                    <div className="mh-skills-inner">
                                        <div className="mh-professional-skill wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.3s">
                                            <h3>Technical Skills</h3>
                                            <div className="each-skills">
                                                <div className="candidatos">
                                                    <div className="parcial">
                                                        <div className="info">
                                                            <div className="nome">Unity</div>
                                                            <div className="percentagem-num">90%</div>
                                                        </div>
                                                        <div className="progressBar">
                                                            <div className="percentagem" style={{ width: "90%" }}></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="candidatos">
                                                    <div className="parcial">
                                                        <div className="info">
                                                            <div className="nome">C#</div>
                                                            <div className="percentagem-num">75%</div>
                                                        </div>
                                                        <div className="progressBar">
                                                            <div className="percentagem" style={{ width: "75%" }}></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="candidatos">
                                                    <div className="parcial">
                                                        <div className="info">
                                                            <div className="nome">Photoshop</div>
                                                            <div className="percentagem-num">60%</div>
                                                        </div>
                                                        <div className="progressBar">
                                                            <div className="percentagem" style={{ width: "60%" }}></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="candidatos">
                                                    <div className="parcial">
                                                        <div className="info">
                                                            <div className="nome">React</div>
                                                            <div className="percentagem-num">50%</div>
                                                        </div>
                                                        <div className="progressBar">
                                                            <div className="percentagem" style={{ width: "50%" }}></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="candidatos">
                                                    <div className="parcial">
                                                        <div className="info">
                                                            <div className="nome">Javascript</div>
                                                            <div className="percentagem-num">50%</div>
                                                        </div>
                                                        <div className="progressBar">
                                                            <div className="percentagem" style={{ width: "50%" }}></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="candidatos">
                                                    <div className="parcial">
                                                        <div className="info">
                                                            <div className="nome">three.js</div>
                                                            <div className="percentagem-num">40%</div>
                                                        </div>
                                                        <div className="progressBar">
                                                            <div className="percentagem" style={{ width: "40%" }}></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-12 col-md-6">
                                    <div className="mh-professional-skills wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.5s">
                                        <h3>Professional Skills</h3>
                                        <ul className="mh-professional-progress">
                                            <li>
                                                <div className="mh-progress mh-progress-circle" data-progress="90"></div>
                                                <div className="pr-skill-name">Communication</div>
                                            </li>
                                            <li>
                                                <div className="mh-progress mh-progress-circle" data-progress="85"></div>
                                                <div className="pr-skill-name">Team Work</div>
                                            </li>
                                            <li>
                                                <div className="mh-progress mh-progress-circle" data-progress="80"></div>
                                                <div className="pr-skill-name">Organisation</div>
                                            </li>
                                            <li>
                                                <div className="mh-progress mh-progress-circle" data-progress="80"></div>
                                                <div className="pr-skill-name">Creativity</div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
        )
    }
}

export default (Skills);