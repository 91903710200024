import { SET_THEME_DARK_MODE} from '../actions/actionTypes'

const initalState = {
    dark: true,
}

const theme = (state = initalState, action) => {
    switch (action.type) {
        case SET_THEME_DARK_MODE:
            return Object.assign({}, state, {
                dark: !state.dark
            })
        default:
            return state
    }
}

export default theme