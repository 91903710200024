import React, { Component } from 'react';
import store from './app/store/index'
import { Provider } from 'react-redux'
import MainPage from './app/containers/MainPage';
import './App.css';
import './app/containers/NavigationBar'
import NavigationBar from './app/containers/NavigationBar';
import SectionLoader from "./app/components/SectionLoader";
import ImageModal from './app/containers/ImageModal';

class App extends Component {

  componentDidMount() {
    document.body.classList.add('black-bg');
    const script = document.createElement("script");

    script.src= "assets/js/custom-scripts.js"
    document.body.appendChild(script);
  }


  render() {
    return (
      <Provider store={store}>
        <SectionLoader />
        <NavigationBar/>
        <MainPage />
        <ImageModal/>
      </Provider>
    );
  }
}

export default App;