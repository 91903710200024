import React, { Component } from 'react';
import { connect } from 'react-redux'

class AboutMe extends Component {
    render() {
        var logoSrc="assets/images/ab-img.png";
        if (!this.props.darkMode) {
            logoSrc="assets/images/ab-img_light.png";
        }
        
        return (
            <section className="mh-about" id="mh-about">
                <div className="container">
                    <div className="row section-separator">
                        <div className="col-sm-12 col-md-6">
                            <div className="mh-about-img shadow-2 wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.4s">
                                <img src={logoSrc} alt="" className="img-fluid" style={{ color: "#000000" }} />
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6">
                            <div className="mh-about-inner">
                                <h2 className="wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.1s">About Me</h2>
                                <p className="wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.2s">Hello, I’m Felix, a Developer and Project Manager based in Germany. I have experience
                                                                                                                in games and apps aswell as managing conventions and events. Here are some of the skills I've got:</p>
                                <div className="mh-about-tag wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.3s">
                                    <ul>
                                        {this.skills(["unity", "c#", "javascript", "react", "three.js", "git", "photoshop", "lightroom", "premiere", "jira", "confluence", "tuleap", "gitlab"])}
                                    </ul>
                                </div>
                                <a href="#mh-skills" className="btn btn-fill wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.4s">my skillset {/*<i className="fa fa-download"></i>*/}</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }

    skills(skills) {
        let skillList = [];
        for (const [index, skill] of skills.entries()) {
            skillList.push(<li key={index}><span>{skill}</span></li>);
        }
        return skillList;
    }
}

const mapStateToProps = (state) => {
    return {
        darkMode: state.theme.dark,
    }
}

export default connect(mapStateToProps)(AboutMe)