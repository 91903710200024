import { SET_IMAGE_MODAL_VISIBILITY, SET_IMAGE_MODAL_SOURCE} from '../actions/actionTypes'

const initalState = {
    enabled: false,
    src: "assets/images/portfolio/g1.jpg",
}

const imageModal = (state = initalState, action) => {
    switch (action.type) {
        case SET_IMAGE_MODAL_VISIBILITY:
            return Object.assign({}, state, {
                enabled: action.value
            })
        case SET_IMAGE_MODAL_SOURCE:
            return Object.assign({}, state, {
                src: action.value,
            })
        default:
            return state
    }
}

export default imageModal