import React, { Component } from 'react';
import { connect } from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import {setImageModalVisibility} from '../actions';

class ImageModal extends Component {
    render() {
        return (
            <div>
                <Modal
                    show={this.props.enabled}
                    onHide={() => this.props.dispatch(setImageModalVisibility(false))}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton style = {{backgroundColor:"#101016"}}>
                        {/* <Modal.Title id="contained-modal-title-vcenter">
                            Modal heading
                </Modal.Title> */}
                    </Modal.Header>
                    <Modal.Body style = {{backgroundColor:"#202026"}}>
                        <img src={this.props.src} style={{width:"100%"}} alt="large img"></img>
                    </Modal.Body>
                </Modal >
            </div>
        )
    }
}

const mapStateToPropsSeries = (state) => {
    return {
        enabled: state.imageModal.enabled,
        src: state.imageModal.src
    }
}

//export default MainPage
export default connect(mapStateToPropsSeries)(ImageModal)