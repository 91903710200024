import React, { Component } from 'react';
import { setImageModalVisibility, setImageModalSource } from '../actions';
import { connect } from 'react-redux'

class PhotoItem extends Component {
    render() {
        return (

            <div className="grid-item col-md-4 col-sm-6 col-xs-12 user-interface general">
                <figure onClick={() => {
                    this.props.dispatch(setImageModalVisibility(true))
                    this.props.dispatch(setImageModalSource("assets/images/portfolio/" + this.props.img + "_L.jpg"))
                }}>
                    <img src={"assets/images/portfolio/" + this.props.img + "_S.jpg"} alt="img" />
                    <figcaption className="fig-caption">
                        <i className="fa fa-search"></i>
                        <h5 className="title">Photography</h5>
                        <span className="sub-title">Large-View</span>
                    </figcaption>
                </figure>
            </div>
        )
    }
}

export default connect()(PhotoItem)