import React, { Component } from 'react';


class WhatIDo extends Component {
    render() {
        return (
            <section className="mh-service">
                <div className="container">
                    <div className="row section-separator">
                        <div className="col-sm-12 text-center section-title wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.2s">
                            <h2>What I do</h2>
                        </div>
                        <div className="col-sm-4">
                            <div className="mh-service-item shadow-1 dark-bg wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.3s">
                                <i className="fa fa-code iron-color"></i>
                                <h3>Development</h3>
                                <p>
                                From Marketing Apps to Games, programming and development are my passion. 
                                My main focus is Unity and c# developing for mobile and desktop but I have 
                                also learned web development using different frameworks.
                            </p>
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div className="mh-service-item shadow-1 dark-bg wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.5s">
                                <i className="fa fa-object-ungroup purple-color"></i>
                                <h3>Project Management</h3>
                                <p>
                                Managing small and large teams and taking responsibility is a big task. 
                                Having the liability for Conventions or Software projects is a challenge 
                                I love to conquer.
                            </p>
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div className="mh-service-item shadow-1 dark-bg wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.7s">
                                <i className="fa fa-bullseye sky-color"></i>
                                <h3>Hobby Photography</h3>
                                <p>
                                Taking photos in the local area or traveling around the world. Photography 
                                is a great project I have found for myself to replenish my creativity and 
                                keep myself excited.
                            </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default (WhatIDo);