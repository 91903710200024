import React, { Component } from 'react';

class EduItem extends Component {
    render() {
        return (
            <div className="mh-education-item dark-bg wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.3s">
                <h4>{this.props.title} from <a href={this.props.link}>{this.props.school}</a></h4> {/* href */}
                <div className="mh-eduyear">{this.props.year}</div>
                {this.props.children}
            </div>
        )
    }
}

export default (EduItem);