import React, { Component } from 'react';
import { connect } from 'react-redux'
import PhotoItem from './PhotoItem';

class Portfolio extends Component {
    render() {
        return (
            <section className="mh-portfolio" id="mh-portfolio">
                <div className="container">
                    <div className="row section-separator">
                        <div className="section-title col-sm-12 wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.1s">
                            <h3>Photography</h3>
                        </div>

                        <div className="part col-sm-12">
                            {/* <div className="portfolio-nav col-sm-12" id="filter-button">
                                <ul>
                                    <li data-filter=".general" className="current wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.1s"> <span>All Categories</span></li>
                                    <li data-filter=".user-interface" className="wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.2s"><span>Web Design</span></li>
                                    <li data-filter=".branding" className="wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.3s"><span>Branding</span></li>
                                    <li data-filter=".mockup" className="wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.4s"><span>Mockups</span></li>
                                    <li data-filter=".ui" className="wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.5s"><span>Photography</span></li>
                                </ul>
                            </div> */}
                            <div className="mh-project-gallery col-sm-12 wow fadeInUp" id="project-gallery" data-wow-duration="0.8s"
                                data-wow-delay="0.5s">
                                <div className="portfolioContainer row">
                                    <PhotoItem img="bird" />
                                    <PhotoItem img="birds" />
                                    <PhotoItem img="coast" />
                                    <PhotoItem img="dam" />
                                    <PhotoItem img="harley" />
                                    <PhotoItem img="ice" />
                                    <PhotoItem img="inside" />
                                    <PhotoItem img="lake" />
                                    <PhotoItem img="landscape" />
                                    <PhotoItem img="mine_1" />
                                    <PhotoItem img="mine_2" />
                                    <PhotoItem img="pier" />
                                    <PhotoItem img="plane" />
                                    <PhotoItem img="river" />
                                    <PhotoItem img="street" />
                                    <PhotoItem img="sunset" />
                                    <PhotoItem img="TigerAndTurtle" />
                                </div>
                            </div>
                        </div>




                    </div>
                </div>
            </section>
        )
    }
}

export default connect()(Portfolio)