import React from 'react';
import { connect } from 'react-redux'
import Toggle from 'react-toggle'
import "react-toggle/style.css"
import "../css/CustomToggle.css"
import {toggleThemeDarkMode} from '../actions';

class NavigationBar extends React.Component {
  constructor(props) {
    super(props);

    this.setDarkMode = this.setDarkMode.bind(this)
  }

  setDarkMode() {
    this.props.dispatch(toggleThemeDarkMode());
  }
  render() {
    return (
      <header className="black-bg mh-header mh-fixed-nav nav-scroll mh-xs-mobile-nav" id="mh-header">
        <div className="overlay"></div>
        <div className="container">
          <div className="row">
            <nav className="navbar navbar-expand-lg mh-nav nav-btn">
              <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon icon"></span>
              </button>

              <div className="collapse navbar-collapse" id="navbarSupportedContent">
                <ul className="navbar-nav mr-auto ml-auto">
                  <li className="nav-item active">
                    <a className="nav-link" href="#mh-home">Home</a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#mh-about">About</a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#mh-featured-project">Projects</a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#mh-skills">Skills</a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#mh-experience">Experiences</a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#mh-portfolio">Photography</a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#mh-contact">Contact</a>
                  </li>
                  <li className="nav-item" style={{flex:1, display:"flex", alignContent:"center", justifyContent:"left"}}>
                      <Toggle
                        defaultChecked={true}
                        onChange={this.setDarkMode} 
                        icons={false}
                        className="custom-toggle"
                        />
                        <span style={{display:"flex",alignSelf:"center",marginLeft:"7px"}}> DarkMode</span>
                  </li>
                </ul>
              </div>
            </nav>
          </div>
        </div>
      </header>
    );
  }
}

const mapStateToPropsSeries = (state) => {
  return {
    darkMode: state.theme.dark,
  }
}

export default connect(mapStateToPropsSeries)(NavigationBar)