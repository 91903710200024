import React, { Component } from 'react';

class WorkItem extends Component {
    render() {
        let responsibilities = [];
        for (const [index, element] of this.props.responsibilities.entries()) {
            responsibilities.push(<li key = {index}><i className="fa fa-circle"></i>{element}</li>);
        }
        return (
            <div className="mh-work-item dark-bg wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.3s">
                <h4>{this.props.title} at <a href={this.props.link}>{this.props.employer}</a></h4> {/* href */}
                <div className="mh-eduyear">{this.props.year}</div>
                {this.props.children}
                <span>Responsibility:</span>
                <ul className="work-responsibility">
                {responsibilities}
                </ul>
            </div>
        )
    }
}

export default (WorkItem);