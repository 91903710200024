import React, { Component } from 'react';
import Carousel from 'react-bootstrap/Carousel'
import CarouselItem from 'react-bootstrap/CarouselItem';


class Projects extends Component {
    render() {
        return (
            <section className="mh-featured-project image-bg featured-img-one" id="mh-featured-project">
                    <div className="img-color-overlay">
                        <div className="container">
                            <div className="row section-separator">
                                <div className="section-title col-sm-12">
                                    <h3>Featured Projects</h3>
                                </div>
                                <div className="col-sm-12 wow fadeInUp" data-wow-duration="0.8s">
                                    <Carousel style={{ paddingBottom: "50px" }}>
                                        <CarouselItem>
                                            <div className="row">
                                                <div className="col-sm-7">
                                                    <div className="mh-featured-project-img shadow-2">
                                                        <img src="assets/images/3DLiveView-Showcase.png" alt="" className="img-fluid" />
                                                    </div>
                                                </div>
                                                <div className="col-sm-5">
                                                    <div className="mh-featured-project-content">
                                                        <h4 className="project-category">Development</h4>
                                                        <h2>3D LiveView</h2>
                                                        <span>AR App</span>
                                                        <p>At Q:Marketing I have been the app developer for the 3D LiveView app developed for Busch Jaeger. 
                                                        Going beyond an Online-Catalog the app delivers a digital view of their products in a modern, 
                                                        interactive, innovative, and yet highly functional way. Working together with Backend Developers and 3D-Artists
                                                        has been a great task and lead to a well-polished product.</p>
                                                        <a href="https://www.wikitude.com/showcase/busch-jaeger-boosts-customer-buying-confidence-with-ar/" 
                                                        target="_blank" rel="noopener noreferrer"
                                                        className="btn btn-fill">View Showcase</a> {/* href */}
                                                    </div>
                                                </div>
                                            </div>
                                        </CarouselItem>
                                        <CarouselItem>
                                            <div className="row">
                                                <div className="col-sm-7">
                                                    <div className="mh-featured-project-img shadow-2">
                                                        <img src="assets/images/SAE_Alumni.jpg" alt="" className="img-fluid" />
                                                    </div>
                                                </div>
                                                <div className="col-sm-5">
                                                    <div className="mh-featured-project-content">
                                                        <h4 className="project-category">Project Management</h4>
                                                        <h2>SAE Alumni Convention 2016 & 2017</h2>
                                                        <span>Convention Floor administration & Logistics</span>
                                                        <p>The SAE Convention is a networking event for creative-media development and technology enthusiasts. 
                                                            Socializing and connecting: the keys to the media industry. <br/>
                                                            Leading a team during set-up, dismantling and throughout the convention is a great task. 
                                                            Hosting the 2016 Alumni Convention for the first Time in Cologne was an even bigger one. 
                                                            Taking challenges and mastering them is an important part of every job, that I love and enjoy.
                                                            </p>
                                                        <a href="https://alumni.sae.edu/2016/10/31/xii-sae-alumni-convention-expo-gallerie/#prettyPhoto" target="_blank" rel="noopener noreferrer" 
                                                        className="btn btn-fill">Website</a> {/* href */}
                                                    </div>
                                                </div>
                                            </div>
                                        </CarouselItem>
                                        <CarouselItem>
                                            <div className="row">
                                                <div className="col-sm-7">
                                                    <div className="mh-featured-project-img shadow-2">
                                                        <img src="assets/images/mockupScantertain.jpg" alt="" className="img-fluid" />
                                                    </div>
                                                </div>
                                                <div className="col-sm-5">
                                                    <div className="mh-featured-project-content">
                                                        <h4 className="project-category">App Development</h4>
                                                        <h2>Scantertain</h2>
                                                        <span>AR App</span>
                                                        <p>The Scantertain Augmented Reality App provides a bridge from print to digital media.
                                                            Hosting the SVWW digital stadium magazine, Scantertain provides an immersive experience 
                                                            beyond traditional magazines. Developing this App was a fun Task and has lead to an 
                                                            appealing App.
                                                        </p>
                                                        <a href="http://www.qmarketing.de/portfolio/scantertain-augmented-reality-app/#" target="_blank" rel="noopener noreferrer" 
                                                        className="btn btn-fill">View Details</a> {/* href */}
                                                    </div>
                                                </div>
                                            </div>
                                        </CarouselItem>
                                        <CarouselItem>
                                            <div className="row">
                                                <div className="col-sm-7">
                                                    <div className="mh-featured-project-img shadow-2">
                                                        <img src="assets/images/btc.jpg" alt="" className="img-fluid" />
                                                    </div>
                                                </div>
                                                <div className="col-sm-5">
                                                    <div className="mh-featured-project-content">
                                                        <h4 className="project-category">Game Development</h4>
                                                        <h2>Bomb The Cube</h2>
                                                        <span>study project</span>
                                                        <p>Bomb the Cube is a three-dimensional look on the old classic Bomberman. As a study 
                                                            project I have lead a team of fellow students and together we developed a split 
                                                            screen Bomberman like game on a cubic world. Developing Games is a creative job and 
                                                            a passion I always had.
                                                        </p>
                                                        <a href="https://www.youtube.com/watch?v=RX8kMqRCkUw&feature=youtu.be&fbclid=IwAR2z_FhYRNOG75t36BqDkrXa4zTWRL56FaCcm9h3Z8hgGRa5z0A1K6IO8EM" target="_blank" rel="noopener noreferrer" 
                                                        className="btn btn-fill">Trailer</a> {/* href */}
                                                    </div>
                                                </div>
                                            </div>
                                        </CarouselItem>
                                        <CarouselItem>
                                            <div className="row">
                                                <div className="col-sm-7">
                                                    <div className="mh-featured-project-img shadow-2">
                                                        <img src="assets/images/wasted.jpg" alt="" className="img-fluid" />
                                                    </div>
                                                </div>
                                                <div className="col-sm-5">
                                                    <div className="mh-featured-project-content">
                                                        <h4 className="project-category">Game Development</h4>
                                                        <h2>Wasted</h2>
                                                        <span>study project</span>
                                                        <p>Wasted is a classic multiplayer arena shooter in an apocalyptic setting. Together 
                                                            with a team of programmers artists and audio engineers, we developed a demo of this 
                                                            game for Quo Vadis and Gamescom. Working with the early stages if Unreal Engine 4 
                                                            has not always been an easy task but resulted in an increase of knowledge and a great
                                                            Bachelor Project.
                                                        </p>
                                                        <a href="https://www.facebook.com/wastedthegame/" target="_blank" rel="noopener noreferrer" 
                                                        className="btn btn-fill">facebook</a> {/* href */} 
                                                    </div>
                                                </div>
                                            </div>
                                        </CarouselItem>
                                    </Carousel>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
        )
    }
}

export default (Projects);