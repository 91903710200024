import React, { Component } from 'react';
import { connect } from 'react-redux'
import Home from '../components/Home'
import AboutMe from '../components/AboutMe'
import WhatIDo from '../components/WhatIDo'
import Projects from '../components/Projects'
import Skills from '../components/Skills'
import Experience from '../components/Experience';
import Portfolio from './Portfolio';
import Contact from '../components/Contact';

class MainPage extends Component {
    // constructor(props) {
    //     super(props);

    //     this.toggleNavbar = this.toggleNavbar.bind(this);
    //     this.state = {
    //         collapsed: true
    //     };
    // }

    // toggleNavbar() {
    //     this.setState({
    //         collapsed: !this.state.collapsed
    //     });
    // }
    render() {
        if (this.props.darkMode===true) {
            document.body.classList.remove('white-vertion');
            document.body.classList.add('dark-vertion');
            console.log("dark")
        } else{
            document.body.classList.remove('dark-vertion');
            document.body.classList.add('white-vertion');
            console.log("light")
        }
        return (
            <div>
                <Home />
                <AboutMe />
                <WhatIDo />
                <Projects />
                <Skills />
                <Experience />
                <Portfolio />
                <Contact />
            </div>
        );
    }
}

const mapStateToPropsSeries = (state) => {
    return {
      darkMode: state.theme.dark,
    }
  }
  
  export default connect(mapStateToPropsSeries)(MainPage)