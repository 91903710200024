import React, { Component } from 'react';
import EduItem from './EduItem';
import WorkItem from './WorkItem';

class Experience extends Component {
    render() {
        return (
            <section className="mh-experince image-bg featured-img-one" id="mh-experience">
                <div className="img-color-overlay">
                    <div className="container">
                        <div className="row section-separator">
                            <div className="col-sm-12 col-md-6">
                                <div className="mh-education">
                                    <h3 className="wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.2s">Education</h3>
                                    <div className="mh-education-deatils">

                                        <EduItem title="Bachelor of Sience (Hons.) Game Programming" school="Middlesex University London" year="2013 - 2016" link="https://www.mdx.ac.uk">
                                            <p>Course content: <br/> Insight into the media-business Management & market economy basics</p>
                                        </EduItem>
                                        <EduItem title="Games Programming Diploma" school="SAE Institute Cologne" year="2013 - 2015" link="https://www.sae.edu/deu/">
                                            <p>Course content: <br/> C#, gamemechanics, engines, C++, ai, networking, tool dev, web, mobile</p>
                                        </EduItem>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-6">
                                <div className="mh-work">
                                    <h3>Work Experience</h3>
                                    <div className="mh-experience-deatils">

                                        <WorkItem title="Unity Developer" employer="Q:MARKETING" year="2017 - NOW" responsibilities = {["App Development"]} link="http://www.qmarketing.de">
                                        </WorkItem>
                                        <WorkItem title="Supervisor" employer="SAE Institute Cologne" year="2014 - 2017" responsibilities = {["Educational student support","Location maintanace","Customer interaction & Front desk", "training new employees","Convention/Fair management","SAE Alumni Convention"]} link="https://www.sae.edu/deu/de/campus/koeln?utm_source=PS01&pfmtr=000001&gclid=CjwKCAjw8e7mBRBsEiwAPVxxiHfEYlYHWopojqiKCpAGfpTLHBARzdnSQj4LQPxFODJN-ZP7k-7MNBoClUcQAvD_BwE">
                                        </WorkItem>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default (Experience);