import React, { Component } from 'react';


class Home extends Component {
    render() {
        return (
            <section className="mh-home image-bg home-2-img" id="mh-home">
                <div className="img-foverlay img-color-overlay">
                    <div className="container">
                        <div className="row section-separator xs-column-reverse vertical-middle-content home-padding">
                            <div className="col-sm-6">
                                <div className="mh-header-info">
                                    <div className="mh-promo wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.1s">
                                        <span>Hello I'm</span>
                                    </div>

                                    <h2 className="wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.2s">Felix Deimel</h2>
                                    <h4 className="wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.3s">Developer, Project Manager, Hobby Photographer</h4>

                                    <ul>
                                        <li className="wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.4s"><i className="fa fa-envelope"></i><a href="mailto:felix@deimel.dev">felix@deimel.dev</a></li>
                                        <li className="wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.5s"><i className="fa fa-phone"></i><a href="callto:+49 177 1566445">+49 177 1566445</a></li>
                                        {/* <li className="wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.6s"><i className="fa fa-map-marker"></i><address>37, Pollsatnd, New York, United State</address></li> */}
                                    </ul>
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="hero-img wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.6s">
                                    <div className="img-border">
                                        <img src="assets/images/hero.png" alt="" className="img-fluid" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default (Home);