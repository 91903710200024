import {SET_IMAGE_MODAL_SOURCE, SET_IMAGE_MODAL_VISIBILITY, SET_THEME_DARK_MODE} from './actionTypes'

export const setImageModalVisibility = (value) => ({
    type:SET_IMAGE_MODAL_VISIBILITY,
    value:value
})

export const setImageModalSource = (value) => ({
    type:SET_IMAGE_MODAL_SOURCE,
    value:value
})

export const toggleThemeDarkMode = (value) => ({
    type:SET_THEME_DARK_MODE,
    value:value
})